import React, { useState } from "react";
import "animate.css";
import "./Cards.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Cards() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ image: "", features: [] });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // const features = [
  //   { name: 'Origin' },
  //   { name: 'Material'},
  //   { name: 'Dimensions' },
  //   { name: 'Finish' },
  //   { name: 'Includes'},
  //   { name: 'Considerations' },
  // ];

  const features = [
    { name: 'Origin', description: 'Manufactured by Microleaf Software Technologies pvt. ltd.' },
    { name: 'Material', description: 'Solid walnut base with rare earth magnets and powder coated steel card cover' },
    { name: 'Dimensions', description: '6.25" x 3.55" x 1.15"' },
    { name: 'Finish', description: 'Hand sanded and finished with natural oil' },
  
  ];

  const handleCardClick = (image) => {
    setModalContent({
      image,
      features,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent({ image: "", features: [] });
  };

  return (
    <div className="relative py-16">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundColor: "lightgray",
          // background:'url("https://images.pexels.com/photos/1260727/pexels-photo-1260727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")'

        }}
      />

      <div className="  absolute inset-0 bg-black bg-opacity-5"></div>

      <div className="relative mx-auto max-w-2xl px-4 lg:max-w-7xl">
        <h2 className="text-center text-gray text-3xl font-bold mb-6">Products</h2>
        <Carousel
          responsive={responsive}
          infinite={true}
          className="skill-slider"
          autoPlay={true}
          autoPlaySpeed={2000}
          transitionDuration={500}
          itemClass="p-2"
          
        >
          {[
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-3-UFsCEB2RzjSZM5_lJ26_0iW2eSBhcDoQ2bdKXLMg&s",
            "https://5.imimg.com/data5/AP/HX/HB/SELLER-36601997/rfid-gym-lockers-250x250.jpg",
            "https://images.jdmagicbox.com/comp/chennai/n4/044pxx44.xx44.180706131023.h6n4/catalogue/microleaf-software-technologies-pvt-ltd-vadapalani-chennai-industrial-locker-dealers-3v5g0x39ce-250.jpg",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy040AXA2qLN-74Sq896HtzecDII-VOn4SjtN6aajAtA&s",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtLVu68knfKnCIPfUrykbQkMqBzmJqXNmvpO_e-jbkwnCrX7fskh-K-StfWq636b_3wKI&usqp=CAU",
            "https://img1.exportersindia.com/product_images/bc-small/2022/4/6207460/automatic-storage-locker-1649668849-6282452.jpeg",
            "https://tiimg.tistatic.com/fp/2/006/993/microleaf-document-storage-digital-rfid-biometric-keypad-otp-locker-444.jpg",
          ].map((image, index) => (
            <button
              key={index}
              className="group"
              // onClick={() => handleCardClick(image)}
            >
              <div className="w-60 h-60 bg-[#E4D7C7] bg-opacity-10 bg-clip-padding p-6 border-4 border-white shadow-lg rounded-lg overflow-hidden flex justify-center items-center">
                <img
                  src={image}
                  alt={`Locker ${index + 1}`}
                  className="w-full h-full object-cover zoom"
                />
              </div>
            </button>
          ))}
        </Carousel>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black bg-opacity-75"
            onClick={closeModal}
          ></div>

<div className="relative bg-white w-11/12 max-w-4xl rounded-lg shadow-lg p-6 flex">
            {/* Close button fix */}
            <button
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-xl font-bold z-50"
              onClick={closeModal}
            >
              ×
            </button>


            <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div className="w-1/1 pr-4">
                <img
                  src={modalContent.image}
                  alt="Modal Content"
                  className="w-full h-auto rounded-md"
                />
              </div>
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Technical Specifications</h2>
        
          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
    
      </div>
    </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cards;
