import React from 'react'

function Collections() {
  return (
    <div>
      <br></br>
          <div className="relative overflow-hidden bg-white">
            <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
              <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                <div className="sm:max-w-lg">
                  <h1 className="text-3xl font-bold tracking-tight sm:text-5xl">
                  <span style={{fontSize:"6rem", fontWeight:"bold"}}>M</span><span style={{fontSize:"2.5rem"}}>ICROLEAF SOFTWARE TECHNOLOGIES PVT LTD</span>
                  </h1>
                  <p className="mt-4 text-xl text-gray-500">
                  We are an acknowledged organization engaged in manufacturing Biometric Lockers, Barcode Lockers, and Biometric Safes. Established in 2015 in Chennai, Tamil Nadu, we are known for our exceptional quality and outstanding finishing at a reasonable cost within a timely delivery period.

                  </p>
                </div>
                <div>
                  <div className="mt-10">
                    {/* Decorative image grid */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                    >
                      <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <div className="flex items-center space-x-6 lg:space-x-8">
                          <div className="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                              <img
                                alt=""
                                src="https://smartlockers.tab.com/wp-content/uploads/2023/11/MM-CityScape-Blue-lores2.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                                alt=""
                                src="https://cdn.prod.website-files.com/631e8b92bc22fc73af38b0a9/657a782a4eab30839a592bd9_custom-smartalock-2-tier.webp"
                                className="size-full object-cover"
                              />
                             
                             
                            </div>
                          </div>
                          <div className="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">

                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://media.licdn.com/dms/image/D5612AQFMM8HoxXgfIg/article-cover_image-shrink_720_1280/0/1714102135855?e=2147483647&v=beta&t=R2tPGZooAjtGxqQQgox0zEFCSIJ0M7qMZZI44_Nmq8U"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://matoma.de/storage/2024/02/Open-Space-Buero-mit-Schliessfachanlage.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                                alt=""
                                src="https://www.zenithinteriors.com/media/exdjvgqb/qbe_syd_891b.jpg?anchor=center&mode=crop&width=720&height=1002&rnd=132829176454300000"
                                className="size-full object-cover"
                              />
                            </div>
                          </div>
                          <div className="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://air-locker.com/wp-content/uploads/elementor/thumbs/BridgePoint_032216a3-nsdroljv2slfhx1co8bebjfx4u218prtmt9s95yawg.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://www.workplacelockers.com/wp-content/uploads/workplace-lockers-public-office.jpg"
                                className="size-full object-cover"
                              />
                            </div>



                            {/* <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://images.jdmagicbox.com/v2/comp/chennai/n4/044pxx44.xx44.180706131023.h6n4/catalogue/microleaf-software-technologies-pvt-ltd-vadapalani-chennai-industrial-locker-dealers-th1oc6ja70.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://img1.exportersindia.com/product_images/bc-full/2019/3/6207460/02-microleaf-smart-baggage-barcode-locker--1552011737.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://tiimg.tistatic.com/fp/1/006/996/microleaf-multiple-doors-digital-rf-id-wrist-band-cabinet-swimming-pool-lockers-888.jpg"
                                className="size-full object-cover"
                              />
                            </div>
                          </div>
                          <div className="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://img1.exportersindia.com/product_images/bc-full/2019/3/6207460/13-microleaf-smart-baggage-rfid-locker--1552011724.jpeg"
                                className="size-full object-cover"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                alt=""
                                src="https://media.licdn.com/dms/image/v2/C561BAQH7YYGp7HtThQ/company-background_10000/company-background_10000/0/1584356145294/microleaf_technologies_cover?e=2147483647&v=beta&t=EAAnZSYT5TQHvC22qXnOYA3PnmkcQEG1CkXEuikZu2A"
                                className="size-full object-cover"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
      
                    
                    <div className="mt-8 align-item-l p-4">
            <button className="bg-gradient-to-r from-blue-500 to-green-500 flex bg-primary justify-start text-white hover:bg-primary/80 duration-500 rounded-lg py-2 px-4" >
              Contact Us
            </button>
          </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        )
    }
export default Collections;