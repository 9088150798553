import React from "react";
import BlogCard from "./BlogCard";


const BlogsData = [
  {
    id: 1,
    image:  "https://tfipost.com/wp-content/uploads/2020/02/1-5-1024x680.jpg", 
    title: "Employees",
    description:
      "“The results have been incredible. With Power Digital, it feels like they’re in our trench, supporting and understanding us. They’re like a partner and mentor in helping us get where we want to be. “The results have been incredible.",
    author: "Someone",
    date: "April 22, 2022",
  },
  {
    id: 2,
    image:"/image.jpeg",
    title: "Manufacturing plant",
    description:
      "“The results have been incredible. With Power Digital, it feels like they’re in our trench, supporting and understanding us. They’re like a partner and mentor in helping us get where we want to be. “The results have been incredible. ",
    author: "Someone",
    date: "April 22, 2022",
  },
  {
    id: 3,
    image: "https://s7280.pcdn.co/wp-content/uploads/2018/04/BMC_IBM_Install_Consortium-700x400.jpg.optimal.jpg",
    title: "Customer Support",
    description:
      "“The results have been incredible. With Power Digital, it feels like they’re in our trench, supporting and understanding us. They’re like a partner and mentor in helping us get where we want to be. “The results have been incredible. ",
    author: "Someone",
    date: "April 22, 2022",
  },
];

const BlogsComp = () => {
    return (
      <>
      

         <div className="bg-gray-100 dark:bg-gray-900 dark:text-white py-10 pb-14">
          <section data-aos="fade-up" className="container ">
            <h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-semibold">
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {BlogsData.map((item) => (
                <BlogCard key={item.id} {...item} />
              ))}
            </div>
            <div className="text-center">
              <button className="bg-gradient-to-r from-blue-500 to-green-500 bg-primary text-white hover:bg-primary/80 duration-500 rounded-lg py-2 px-4">View All Posts</button>
            </div>
          </section>
        </div> 
      </>
    );
  };
  

export default BlogsComp;