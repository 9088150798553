


import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const FooterLinks = [
  { title: "About", link: "/about" },
  { title: "Products", link: "/cards" },
  { title: "Careers", link: "/careers" },
  { title: "Contact", link: "/connect" },
];

const Footer = () => {
  return (
    <div className="bg-black text-white">
      <section className="container mx-auto py-10">
        <div className="grid md:grid-cols-3 gap-8 py-5">
          {/* About Section */}
          <div className="text-center pl-6">
            <h1 className="text-2xl sm:text-2xl font-bold mb-4 m-4">
              MICROLEAF SOFTWARE TECHNOLOGIES PVT LTD
            </h1>
            <p className="text-sm text-gray-400 leading-relaxed">
              We are an acknowledged organization engaged as a manufacturer of Biometric Locker, Barcode Scanning Locker, and Biometric Safes.
            </p>
            <div className="flex justify-center items-center gap-4 mt-6">
              <a href="https://www.instagram.com/microleaf_2015/?igsh=MnVteDdqZDAxbTd1"
          aria-label="Instagram" target="_blank"
          rel="noopener noreferrer" cursor="pointer">
                <FaInstagram className="text-2xl hover:text-gray-500 duration-300" />
              </a>
              <a href="#"target="_blank"
                rel="noopener noreferrer" aria-label="Facebook">
                <FaFacebook className="text-2xl hover:text-gray-500 duration-300" />
              </a>
              <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A38129703&keywords=microleaf%20software%20technologies%20pvt.%20ltd.&origin=RICH_QUERY_SUGGESTION&position=2&searchId=d448c04d-5701-4446-8d3d-cf210ba0b2ef&sid=jxU&spellCorrectionEnabled=false" target="_blank"
                rel="noopener noreferrer" aria-label="LinkedIn">
                <FaLinkedin className="text-2xl hover:text-gray-500 duration-300" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid grid-cols-2 sm:grid-cols-1 gap-6 text-center">
            <div>
              <h2 className="text-xl font-semibold mb-4">QUICK LINKS</h2>
              <ul className="space-y-3">
                {FooterLinks.map((link) => (
                  <li
                    key={link.title}
                    className="text-gray-400 hover:text-white duration-300 cursor-pointer"
                  >
                    {link.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact Section */}
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">CONTACT US</h2>
            <p className="text-sm text-gray-400 leading-relaxed">
              <strong>Building No.1, 2nd floor,</strong>
              <br />
              Perumal Koil Street, Koyembedu, Chennai.
              <br />
              <strong>Pincode:</strong> 600 107
              <br />
              <strong>Phone:</strong> +91 99529 14821 / 044 - 48584268
              <br />
              <strong>Email:</strong>
              <br />
              <a
                href="mailto:microleaftech@yahoo.com"
                className="hover:underline text-white"
              >
                microleaftech@yahoo.com
              </a>
              <br />
              <a
                href="mailto:admin@microleaftech.in"
                className="hover:underline text-white"
              >
                admin@microleaftech.in
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
        <div className="container mx-auto text-center">
          <p className="text-sm text-gray-500">
            © 2024 Microleaf Software Technologies Pvt. Ltd. All rights
            reserved.
          </p>
        </div>
    </div>
  );
};

export default Footer;
