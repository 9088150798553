import React from "react";
import { Box } from "@mui/joy";
import { Card } from "react-bootstrap";

const Careers = () => {
  return (
    <div className="relative bg-gray-100 dark:bg-gray-900 dark:text-white py-10 pb-14 text-gray min-h-screen flex items-center justify-center">
      {/* Background Image */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center"
        style={{
          backgroundColor: "lightgray",
          background:'url("https://media.istockphoto.com/id/1435518101/photo/brick-wall-antique-old-grunge-white-texture-background.jpg?b=1&s=612x612&w=0&k=20&c=-RNiW_JBBpmcRXW5koe6tZI0rUaXGT9QO0WyfjYxL0c=")'
        }}
      />

      {/* Content Container */}
      <div
        className="relative flex flex-col lg:flex-row w-full p-6  bg-transparent   shadow-lg rounded-lg bg-transparent max-w-7xl justify-between items-center z-10"
        // style={{
        //   boxShadow:
        //     "5px 5px 15px rgba(0, 0, 0, 0.5), -5px -5px 10px rgba(255, 255, 255, 0.3)",
        // }}
      >
        {/* Text Content */}
        <div className="w-full lg:w-1/2 p-4 text-xs  text-gray-900  rounded-lg mb-6 lg:mb-0">
          <p className="leading-relaxed text-xs sm:text-base lg:text-lg text-justify">
          Our prospective customers are IT Firms, Manufacturers, Defence, Engineering Co., Pharmaceutical, Garments, Hotels, Retailers, Educational & Research Institutions, Private Examination Centre’s, Automobile, and many more. Our Smart locker technologies are RFID, Biometrics, and Biometric with OTP, Barcode token, PIN / Passcode, IOT / Cloud technology and many more. Microleaf Manufacturing and supply of
            <br />
            <p className="text-left text-sm mt-4">
              - Biometric / RFID / PIN / OTP -based Mobile Lockers / Baggage
              Lockers
              <br />
              - Barcode / QR code controlled Lockers / Supermarket Lockers
              <br />
              - SMART Courier Lockers
              <br />
              - Swimming Pool / GYM RFID Lockers / School Digital Lockers
              <br />
              - Centralized & Cabinet type -Cash-safe Lockers
              <br />
              - Hotel RFID Door Locks with Software & Hotel Lock Solutions
              <br />
              - Residential Biometric / RFID / PIN Door Locks
              <br />
              - Web based Applications development and Service
              <br/>
              - Embedded technical Solutions and Software Product development.
            </p>
          </p>
        </div>

        {/* Video Section */}
        <div className="w-full lg:w-1/2 p-4">
          <div className="card">
            <Card
              style={{
                backgroundColor: "black",
                width: "100%",
                height: "auto", // Adjust height dynamically
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%", // 16:9 Aspect Ratio
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <video
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: "black",
                  }}
                  src="/loo.mp4" // Path to your video file
                  type="video/mp4"
                  autoPlay
                  muted
                  loop
                  controls={false}
                  playsInline
                />
              </Box>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
