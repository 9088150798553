import React from 'react'

function Trusted() {

        return (
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <h2 className="text-center text-lg/8 font-semibold text-gray-900">
                Trusted by the world’s most innovative teams
              </h2>
              <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img
                  alt="vestas"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqFwMS8wYHkQHDYg67s_RKBdvDnSSBBWyfJQ&s"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <img
                  alt="Samsung"
                  src="https://upload.wikimedia.org/wikipedia/commons/f/fe/Samsung_Galaxy_A8_2018_logo.png"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <img
                  alt="Viatris"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Viatris.svg/1280px-Viatris.svg.png"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <img
                  alt="Yokohama"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqVKWn7PrRnVjyaqXAXdBprZYp0pLr6-a5wA&s"
                  width={160}
                  height={68}

                  className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                />
                <img
                  alt="FOXTEL"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxV4v3BCPNMjizyC1pojdp56T3yBH1Bwl_Sw&s"
                  width={168}
                  height={58}
                  className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                />
              </div>
            </div>
          </div>
        )
      }
  

export default Trusted