import React from "react";
import { Box } from "@mui/joy";
import { Card } from "react-bootstrap";

const Careers = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900 dark:text-white py-10 pb-14 text-gray min-h-screen flex items-center justify-center">
      <div className="flex w-full bg-clip-padding p-6 bg-black-600 border-4 border-black-300 border-dashed items shadow-lg rounded-lg bg-white max-w-7xl justify-between items-center">
        {/* Video Section on the Right */}
        <div className="w-1/2 p-4 bg-white">
          <div className="card">
            <Card
              style={{
                backgroundColor: "black",
                width: "100%",
                height: "80%", // Optional: To adjust card height
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%", // 16:9 Aspect Ratio
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <video
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: "black",
                  }}
                  src="/loo.mp4" // Path to your video file
                  type="video/mp4"
                  autoPlay
                  muted
                  loop
                  controls={false}
                  playsInline
                />
              </Box>
            </Card>
          </div>
        </div>

        {/* Text Content on the Left */}
        <div className="w-1/2 p-4 bg-gradient-to-r from-blue-500 to-green-500 text-white">
          <p className="leading-relaxed">
            MICROLEAF is an ambitious paradise for embedded technologists to
            implement creativity & innovation. We primarily deal with digitally
            customized smart lockers. We pride ourselves as one of the first
            entrants in developing Digital Locker Supplier, and the first
            automatic multi-door locker system provider in India.
            <br />
            
            <p className="text-left" >
            - Biometric / RFID / PIN / OTP -based Mobile Lockers / Baggage Lockers
            <br />
            - Barcode / QR code controlled Lockers / Supermarket Lockers
            <br />
            - SMART Courier Lockers
            <br />
            - Swimming Pool / GYM RFID Lockers / School Digital Lockers
            <br />
            - Centralized & Cabinet type -Cash-safe Lockers
            <br />
            - Hotel RFID Door Locks & Hotel Lock Solutions
            <br />
            - Biometric / RFID / PIN Residential Door Locks
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Careers;
