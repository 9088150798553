import React from 'react';
import 'animate.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Cards() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="mx-auto max-w-2xl px-4 py-16 lg:px-6 lg:py-24 lg:max-w-7xl lg:px-8">
      <h2 className="sr-only text-black">Products</h2>
      <Carousel
        responsive={responsive}
        infinite={true}
        className="skill-slider"
        autoPlay={true}
        autoPlaySpeed={2000}
        transitionDuration={500}
        itemClass="p-2" 
      >
        <a href="#" className="group">
          <div className="w-60 h-60  bg-clip-padding p-6 bg-black-600 border-4 border-black-300 border-dashed items shadow-lg rounded-lg overflow-hidden flex justify-center items-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-3-UFsCEB2RzjSZM5_lJ26_0iW2eSBhcDoQ2bdKXLMg&s"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        <a href="#" className="group">
          <div className="w-60 h-60 bg-clip-padding p-6 bg-black-600 border-4 border-black-300 border-dashed bg-gray-200 items shadow-lg rounded-lg overflow-hidden flex justify-center items-center">
            <img
              src="https://5.imimg.com/data5/AP/HX/HB/SELLER-36601997/rfid-gym-lockers-250x250.jpg"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        <a href="#" className="group">
          <div className="w-60 h-60 bg-gray-200 bg-clip-padding p-6 bg-black-600 border-4 border-black-300 border-dashed items shadow-lg rounded-lg overflow-hidden flex justify-center items-center">
            <img
              src="https://images.jdmagicbox.com/comp/chennai/n4/044pxx44.xx44.180706131023.h6n4/catalogue/microleaf-software-technologies-pvt-ltd-vadapalani-chennai-industrial-locker-dealers-3v5g0x39ce-250.jpg"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        <a href="#" className="group ">
          <div className="w-60 h-60 bg-gray-200 shadow-lg bg-clip-padding p-6 bg-black-600 border-4 border-black-300 items border-dashedrounded-lg overflow-hidden flex justify-center items-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy040AXA2qLN-74Sq896HtzecDII-VOn4SjtN6aajAtA&s"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        <a href="#" className="group">
          <div className="w-60 h-60 bg-gray-200 shadow-lg rounded-lg bg-clip-padding p-6 bg-black-600 border-4 border-black-300 items border-dashed overflow-hidden flex justify-center items-center">
            <img
              src="https://2.wlimg.com/product_images/bc-lgall/2023/11/6207460/ms-locker-1677607717-6780291.jpg"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        <a href="#" className="group">
          <div className="w-60 h-60 bg-gray-200 shadow-lg rounded-lg overflow-hidden bg-clip-padding p-6 bg-black-600 border-4 items border-black-300 border-dashed flex justify-center items-center">
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2022/10/HO/TA/AR/36601997/7-3-250x250.jpg"
              alt="Locker"
              className="w-full h-full object-cover"
            />
          </div>
        </a>
        
      </Carousel>
    </div>
  );
}

export default Cards;
