import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router";
import Products from "./Products";
import Markets from "./Markets";
import About from "./About";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Careers from "./Careers";
import Home from "./Home";
import Connect from "./Connect";
import AboutUs from "./AboutUs";
import Careers1 from "./Careers1";
import Navbar1 from "./Navbar1";
import Products1 from "./Products1";
import Chatbot from "./Chatbot";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BlogsComp from "./Blog/BlogsComp.js";
import Services from "./Sevices.js";
import Hero from "./Hero.js";
import Testimonial from "./Testimonal.js";
import BrandsLogo from "./BrandsLogo.js";
import Cards from "./Cards.js";
import DarkMode from "./Darkmode.js";
import ResponsiveMenu from "./ResponsiveMenu.js";
import Trusted from "./Trusted.js";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about " element={<About />} />
        <Route exact path="/chat" element={<Chatbot />} />
        <Route exact path="/dark" element={<DarkMode />} />
        <Route exact path="/res" element={<ResponsiveMenu />} />
        <Route exact path="/trusted" element={<Trusted />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products1" element={<Products1 />} />
        <Route path="/blog" element={<BlogsComp />} />
        <Route path="/services" element={<Services />} />
        <Route path="/hero" element={<Hero />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers1" element={<Careers1 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/navbar" element={<Navbar />} />
        <Route path="/navbar1" element={<Navbar1 />} />
        <Route path="/test" element={<Testimonial />} />
        <Route path="/brand" element={<BrandsLogo />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>


      {/* <Chatbot /> */}
    </div>
  );
}

export default App;
