import React from "react";
import "./Hero.css";
import Hero from "./Hero";
import Services from "./Sevices";
import Cards from "./Cards";
import Testimonial from "./Testimonal";
import BlogsComp from "./Blog/BlogsComp";
import Contact from "./Contact";
import Careers from "./Careers";
import Client from "./Client";
import Footer from "./Footer";
import Trusted from "./Trusted";
import About from "./About";
import Collections from "./Collections";

const Home = () => {
  return (
    <div>
      <div className="relative w-full h-screen overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full px-4 sm:px-8 md:px-16 lg:px-24">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover position-fixed"
            src="/mic.mp4"
            type="video/mp4"
            autoPlay
            muted
            loop
            playsInline
          />
        </div>

        <div className="absolute inset-0 flex justify-start pl-20 p-6 items-center text-center">
          <div className="text-white text-left justify-left">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
              Welcome to Microleaf
              <br></br>Smart Lockers
            </h1>
            <br></br>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl mb-6">
              Innovating embedded technologies with
              <br></br> cutting-edge smart locker solutions.
            </p>
            <button className="bg-gradient-to-r from-blue-500 to-green-500 text-white py-2 px-4 rounded-lg text-sm sm:text-base md:text-lg">
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div>
        <Hero />
        <Services />
        <Cards />
        <Careers />
        <Trusted />
        <Client />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
