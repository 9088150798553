import "./App.css";


import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
const Hero = () => {
  return (
    <div className="dark:bg-gray-950 dark:text-white duration-300 ">
      <div className="container min-h-[620px] flex mt-10 sm:mt-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 place-items-center">
        <div data-aos="zoom-in" className="order-1 sm:order-2 relative">
            <img
              src="https://www.workplacelockers.com/wp-content/uploads/workplace-lockers-public-office.jpg"
              alt=""
              className="w-full sm:max-w-[700px] md:max-w-[700px]"
            />
            <div
              data-aos="slide-right"
              className="absolute -bottom-5 -right-8 px-4 py-2 rounded-xl bg-white dark:bg-gray-900 dark:text-white shadow-md"
            >
              <p className="text-gray-500 text-sm">⭐Projects</p>
              <h1 className="font-bold">
                600+ <span className="font-normal">Done</span>
              </h1>
            </div>
            </div>
      

          {/* Text section */}
      <div className="space-y-5 order-2 sm:order-1 xl:pr-40 pl-20">
            <h1
              data-aos="fade-up"
              className="text-4xl sm:text-5xl font-semibold "
              style={{ lineHeight: 1.2 }}
            >
            About{" "}
              <span className="text-primary bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text  flex flex-col items-center justify-center" >Microleaf</span>
            </h1>
            <p data-aos="fade-up" data-aos-delay="300" className="text-justify">
  <strong>MICROLEAF</strong> is an ambitious paradise for web applications and embedded technologists to implement creativity and innovation. Established in 2015, the company primarily deals with digitally customized smart lockers.
 
  We pride ourselves as one of India’s first entrants in developing digital lockers and the first automatic multi-door locker system providers. As the fastest-growing customized automatic locker manufacturer in India, Microleaf serves top corporate companies across the country and globally.
</p>

            <br></br>
            <a
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-offset="0"
              className="bg-gradient-to-r from-blue-500 to-green-500 bg-primary text-white hover:bg-primary/80 duration-500 rounded-lg py-2 px-4"
              href="https://www.google.com/search?q=microleaf+software+technologies+pvt+lockers+of+microleaf&oq=microleaf+&gs_lcrp=EgZjaHJvbWUqBggBEEUYOzIGCAAQRRg5MgYIARBFGDsyCAgCEEUYJxg7MggIAxBFGCcYOzIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEINzM2MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8"
            >
              Learn More
            </a>
          </div>   
        </div>
      </div>
    </div>
  );
};

export default Hero;







