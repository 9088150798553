import React from "react";


const BrandsLogo = () => {
  return (
    <>
      <div className="container mb-12 mt-12 sm:mt-0">
        <h1 className="text-center">Powering next-gen companies</h1>
        <div className="py-6 md:px-32 flex flex-wrap items-center justify-evenly gap-3">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa-NcHDIJ9UgKadjFYWfViV9iHc9S6sVFRag&s" alt="" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa-NcHDIJ9UgKadjFYWfViV9iHc9S6sVFRag&s" alt="" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa-NcHDIJ9UgKadjFYWfViV9iHc9S6sVFRag&s" alt="" />
          <img src="https://images.jdmagicbox.com/comp/chennai/n4/044pxx44.xx44.180706131023.h6n4/catalogue/microleaf-software-technologies-pvt-ltd-vadapalani-chennai-industrial-locker-dealers-3v5g0x39ce-250.jpg" alt="react" />
          <img src="https://2.wlimg.com/product_images/bc-small/2023/11/6207460/ms-locker-1677487717-6780291.jpg" alt="node" />
          <img src="https://tiimg.tistatic.com/fp/1/007/001/water-and-theme-park-key-less-electronic-lockers-971.jpg" alt="sql" />
          <img src="https://2.wlimg.com/product_images/bc-small/2023/11/6207460/ms-locker-1677487717-6780291.jpg" alt="node" />
          <img src="https://5.imimg.com/data5/SELLER/Default/2022/10/HO/TA/AR/36481997/7-3-250x250.jpg" alt="express" />
          <img src="https://tiimg.tistatic.com/fp/1/005/491/reliable-biometric-safety-locker-207.jpg" alt="express" />
          <img src="https://5.imimg.com/data5/SELLER/Default/2022/12/OL/LA/RY/36481997/microleaf-smart-mobile-phone-cabin-locker-edited-250x250.jpg" alt="express" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6TZP-_PIeMunUxJGKVPLTtFw0TZgz2mJj6ltKBredZ4c4a5wzCkSRlT7MioQprnsHkFs&usqp=CAU" alt="express" />

        </div>
      </div>
    </>
  );
};

export default BrandsLogo;