import React from 'react'
// import './About.css';

// function About() {
//   return (
//     <div className='abo'>

{/* <div className="about-page">
  <section className="about-content">
    <br></br>
    <h1 className="about-heading">About Company</h1>
    <br></br>
    <br></br>

    <p className="about-description">
      MICROLEAF is an ambitious paradise for embedded technologists to implement creativity & innovation. 
      We primarily deal with digitally customized smart lockers. 
      We pride ourselves as one of the first entrants in developing Digital Locker Supplier, and the first 
      automatic multi-door locker system provider in India.
    </p>
  </section>
  <br></br>

  <section className="about-images">
    <img 
      src="https://tiimg.tistatic.com/fp/1/006/998/microleaf-apartment-building-otp-rfid-biometric-locker-237.jpg" 
      alt="Smart Locker" 
    />
  </section>
  <br></br>

</div> */}

{/* <div class="relative ...">
  <button type="button" class="...">
    Options
  </button>

  <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
    <div class="rounded-md bg-white shadow-xs">
    </div>
  </div>
</div> */}



     {/* <div className='about'>
      <h1 className='a'>
     About company
      </h1>
      <br></br>
     

      <h4>
         MICROLEAF is an ambitious paradise for the embedded technologists to implement the creativity & innovation. We primarily deal with digitally customized smart lockers. We pride ourselves as the one of the first entrants in developing Digital Locker Supplier, and the first automatic multi-door locker system provider in India..
      </h4>
      
    </div>
    <div className='about2'>

 <div  className='img'>
<img src="https://tiimg.tistatic.com/fp/1/006/998/microleaf-apartment-building-otp-rfid-biometric-locker-237.jpg" />
</div>
<br></br> 

 
        </div> 
    </div>
  )
}

export default About */}


function About() {

  const links = [
    { name: 'Open roles', href: '#' },
    { name: 'Internship program', href: '#' },
    { name: 'Our values', href: '#' },
    { name: 'Meet our leadership', href: '#' },
  ]
  const stats = [
    { name: 'Full-time colleagues', value: '300+' },
    { name: 'Hours per week', value: '45' },
    { name: 'Paid time off', value: 'Unlimited' },
  ]
  return (
    // <div className="flex flex-col md:flex-row justify-between items-center p-8 bg-gradient-to-r from-gray-50 to-gray-100">
    //   <div className="w-full md:w-1/2 p-6 bg-white shadow-xl rounded-lg">
    //     <h1 className="text-5xl font-extrabold text-gray-700 mb-6">
    //       About Us
    //     </h1>
    //     <p className="text-gray-600 text-lg text-left leading-relaxed">
    //       At <span className=" font-semibold leading-relaxed text-left " style={{ textColor: 'linear-gradient(to right,green, #005BB5)' }}>MICROLEAF</span>, we
    //       are dedicated to advancing embedded technologies with creativity and
    //       innovation. As pioneers in the smart locker industry, we specialize
    //       in delivering digitally customized solutions that cater to a wide
    //       range of needs. We are proud to be India's first automatic multi-door
    //       locker system provider, setting a benchmark in smart automation.
    //     </p>
    //     <div className="mt-6">
    //          <button className="px-6 py-3 px-6 py-3  bg-green-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-[#005BB5]-300 transition-transform transform hover:scale-105 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-[#0072CE] transition-transform transform hover:scale-105 justify-right"  > 

    //         Learn More
    //       </button>
    //     </div>
    //   </div>

    //   <div className="w-full md:w-1/2 p-6 mt-6 md:mt-0 flex justify-center">
    //     <img
    //       src="https://tiimg.tistatic.com/fp/1/006/998/microleaf-apartment-building-otp-rfid-biometric-locker-237.jpg"
    //       alt="Smart Locker"
    //       className="w-full h-96 object-cover rounded-lg shadow-xl transform hover:scale-105 transition duration-500"
    //     />
    //   </div>
    // </div>


    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
    <img
      alt=""
      src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
      className="absolute inset-0 -z-10 size-full object-cover object-right md:object-center"
    />
    <div
      aria-hidden="true"
      className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
      />
    </div>
    <div
      aria-hidden="true"
      className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
      />
    </div>
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">Work with us</h2>
        <p className="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
          Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
          fugiat veniam occaecat fugiat.
        </p>
      </div>
      <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-white sm:grid-cols-2 md:flex lg:gap-x-10">
          {links.map((link) => (
            <a key={link.name} href={link.href}>
              {link.name} <span aria-hidden="true">&rarr;</span>
            </a>
          ))}
        </div>
        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col-reverse gap-1">
              <dt className="text-base/7 text-gray-300">{stat.name}</dt>
              <dd className="text-4xl font-semibold tracking-tight text-white">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>



  );
}

export default About;


