import React from "react";
import { CursorArrowRaysIcon, SquaresPlusIcon } from "@heroicons/react/24/solid";
import { PlayCircleIcon, PhoneIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const megaDropdownContent = [
 
  {
    title: "Industries",
    items: [
      {  description: "Smart Digital Locker", href: "#" },
      {  description: "Industrial Manual Locker", href: "#" },
      {  description: "Office Furniture", href: "#" },
      {  description: "Smart Door Locks", href: "#" },


    ],
  },
  {
    title: "Services",
    items: [
      {  description: "Applications Development", href: "#" },
      {  description: "Website Design & Development", href: "#" },
      { description: "Embedded Technology Solutions", href: "#" },
      {  description: "SEO Service", href: "#" },
      {  description: "Import & Export Service", href: "#" },

    ],
  },
];

const solutionsDropdownContent = [
  {
    title: "Solutions",
    items: [
      { name: "Engagement", description: "Speak directly to your customers", href: "#" },
      { name: "Integrations", description: "Connect with third-party tools", href: "#" },
      { name: "Automations", description: "Build strategic funnels that will convert", href: "#" },
    ],
  },
];

const Navbar = () => {
  return (
    <nav className="containe fixed top-0 left-0 w-full bg-white shadow-md text-gray-800 p-4 z-50">
      <div className="container1 mx-auto flex justify-between items-center">
        {/* Logo */}
        <img className=" c1 text-2xl font-bold h-8" src="/im.png" alt="Logo" />

        {/* Navigation Links */}
        <div className=" container2 flex items-center space-x-6">
          <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
            Home
          </a>

          {/* Mega Dropdown: Explore */}
          <div className="relative group pr ">
            <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
              Products
            </a>
            <div className="absolute  right-0 top-12 w-[900px] p-10 bg-white shadow-lg hidden group-hover:block z-20">
              <div className="container mx-auto grid grid-cols-3 gap-8 p-6">
                {megaDropdownContent.map((section) => (
                  <div key={section.title}>
                    <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                    <ul className="mt-2 space-y-2">
                      {section.items.map((item) => (
                        <li key={item.name}>
                          {/* <a
                            href={item.href}
                            className="block text-sm font-medium text-gray-700 hover:text-indigo-600"
                          >
                            {item.name}
                          </a> */}
                          <p className="text-sm text-gray-500">{item.description}</p>
                        </li>

                      ))}

                    </ul>

                  </div>

                ))}
                    <img src="https://media.istockphoto.com/id/1414801614/photo/school-lockers-with-items-equipments-and-accessoires-for-education-back-to-school.jpg?s=612x612&w=0&k=20&c=YFiCaSkPfibnzlpY8D9d9lk8qnsw1Ll6c8swlhJcibg="/>

              </div>
            </div>
          </div>

          {/* Mega Dropdown: Solutions */}
          {/* <div className="relative group ">
            <a href="#" className="flex text-sm font-semibold text-gray-900 hover:text-indigo-600 w-full align-center justify-center">
              Solutions
            </a>
            <div className="absolute  right-0 top-12 w-[900px] p-10 mx-auto bg-white shadow-lg hidden group-hover:block z-20">
  <div className="container mx-auto grid grid-cols-3 gap-8 p-6">
    {solutionsDropdownContent.map((section) => (
      <div key={section.title}>
        <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
        <ul className="mt-2 space-y-2">
          {section.items.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                className="block text-sm font-medium text-gray-700 hover:text-indigo-600"
              >
                {item.name}
              </a>
              <p className="text-xs text-gray-500">{item.description}</p>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
</div>

          </div> */}

           {/* Mega Dropdown: Explore */}
           <div className="relative group">
            <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
            About Us
            </a>
            {/* <div className="absolute right-0 top-12 w-[900px] p-10 bg-white shadow-lg hidden group-hover:block z-20">
              <div className="container mx-auto grid grid-cols-3 gap-8 p-6">
                {megaDropdownContent.map((section) => (
                  <div key={section.title}>
                    <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                    <ul className="mt-2 space-y-2">
                      {section.items.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className="block text-sm font-medium text-gray-700 hover:text-indigo-600"
                          >
                            {item.name}
                          </a>
                          <p className="text-xs text-gray-500">{item.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div> */}
          </div>

           {/* Mega Dropdown: Explore */}
           {/* <div className="relative group">
            <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
             Markets
            </a>
            <div className="absolute right-0 top-12 w-[900px] p-10 bg-white shadow-lg hidden group-hover:block z-20">
              <div className="container mx-auto grid grid-cols-3 gap-8 p-6">
                {megaDropdownContent.map((section) => (
                  <div key={section.title}>
                    <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                    <ul className="mt-2 space-y-2">
                      {section.items.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className="block text-sm font-medium text-gray-700 hover:text-indigo-600"
                          >
                            {item.name}
                          </a>
                          <p className="text-xs text-gray-500">{item.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

           {/* Mega Dropdown: Explore */}
           <div className="relative group">
            <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
            Careers
            </a>
            {/* <div className="absolute right-0 top-12 p-10 w-[900px] bg-white shadow-lg hidden group-hover:block z-20">
              <div className="container mx-auto grid grid-cols-3 gap-8 p-6">
                {megaDropdownContent.map((section) => (
                  <div key={section.title}>
                    <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                    <ul className="mt-2 space-y-2">
                      {section.items.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className="block text-sm font-medium text-gray-700 hover:text-indigo-600"
                          >
                            {item.name}
                          </a>
                          <p className="text-xs text-gray-500">{item.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div> */}
          </div>



        
          <a href="#" className="text-sm font-semibold text-gray-900 hover:text-indigo-600">
            Contact Us
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
