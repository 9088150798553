// import React from "react";
// import './About.css';

// function Contact() {
//   return (
//     <div className="cont">
//       <div className="con">
//         <div className="co">
//           <h2 className="m">MICROLEAF TECHNOLOGIES PVT LIMITED</h2>
//           <div className="quote-container">
    
//     </div>
//           <i>
//           <h3 className="pro">
//             We are acknowledged organization, are engaged as manufacturer of Biometric
//             Locker, Barcode Locker, Biometric Safes. It was established in the
//             year 2015 at Chennai, Tamil Nadu. These products are known for their
//             most far-fetched quality and astonishing finishing at the reasonable
//             cost in the stimulate time period.
//           </h3>
//           </i>
         
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Contact;
// import React from "react";

// function Contact() {
//   return (
//     <div
//       className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
//       style={{
//         backgroundImage: `url('https://media.istockphoto.com/id/1318648581/photo/an-open-and-empty-silver-steel-safe-box-template-on-blue-background-with-copy-space.jpg?s=612x612&w=0&k=20&c=KgNK8akGs7rFXXrjDfwV0cBgrY7NoTSDIhVAap1JQFw=')`,
//       }}
//     >
//       <div className="absolute inset-0 bg-black bg-opacity-70"></div>

//       <div className="relative z-10 text-center px-6 md:px-12">
//         <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
//           MICROLEAF TECHNOLOGIES PVT LIMITED
//         </h2>
//         <p className="text-lg md:text-xl text-white leading-relaxed">
//             We are an acknowledged organization engaged in manufacturing
//             Biometric Lockers, Barcode Lockers, and Biometric Safes. Established
//             in 2015 in Chennai, Tamil Nadu, we are known for our exceptional
//             quality and outstanding finishing at a reasonable cost within a
//             timely delivery period.
//         </p>

//         <div className="mt-8">
//           <button className="px-6 py-3 bg-green-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105">
//             Contact Us
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Contact;
import React from "react";
import { useNavigate } from "react-router";

function Contact() {
  const navigate=useNavigate();
  return (
    <div
      className="relative min-h-screen flex items-center bg-cover bg-center"
      style={{
        backgroundImage: `url('https://img.freepik.com/premium-vector/padlock-security-cyber-digital-concept-abstract-technology-background-protect-system-innovation_42421-939.jpg')`,
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>

      <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 w-full px-6 md:px-12">
    {/* Text Section (Left Half) */}
    <div className="flex flex-col text-left justify-center items-start px-6 md:px-12 text-white">
      <h2 className="text-4xl md:text-5xl font-bold mb-6">
        MICROLEAF SOFTWARE TECHNOLOGIES PVT LTD
      </h2>
      <p className="text-lg md:text-xl leading-relaxed">
        We are an acknowledged organization engaged in manufacturing
        Biometric Lockers, Barcode Lockers, and Biometric Safes. Established
        in 2015 in Chennai, Tamil Nadu, we are known for our exceptional
        quality and outstanding finishing at a reasonable cost within a
        timely delivery period.
      </p>

          {/* Call-to-Action Button */}
          <div className="mt-8">
            <button className="bg-gradient-to-r from-blue-500 to-green-500 bg-primary text-white hover:bg-primary/80 duration-500 rounded-lg py-2 px-4" onClick={() => navigate("/connect")}>
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
// import React from "react";

// function Contact() {
//   return (
//     <div
//       className="relative min-h-screen flex items-center bg-cover bg-center"
//       style={{
//         backgroundImage: `url('https://media.istockphoto.com/id/1318648581/photo/an-open-and-empty-silver-steel-safe-box-template-on-blue-background-with-copy-space.jpg?s=612x612&w=0&k=20&c=KgNK8akGs7rFXXrjDfwV0cBgrY7NoTSDIhVAap1JQFw=')`,
//       }}
//     >
//       <div className="absolute inset-0 bg-black bg-opacity-70"></div>

//       <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 w-full px-6 md:px-12">
//         <div className="hidden md:block"></div>

//         <div className="text-left text-white flex flex-col justify-center items-start px-6 md:px-12">
//           <h2 className="text-4xl md:text-5xl font-bold mb-6">
//             MICROLEAF TECHNOLOGIES PVT LIMITED
//           </h2>
//           <p className="text-lg md:text-xl leading-relaxed">
//             We are an acknowledged organization engaged in manufacturing
//             Biometric Lockers, Barcode Lockers, and Biometric Safes. Established
//             in 2015 in Chennai, Tamil Nadu, we are known for our exceptional
//             quality and outstanding finishing at a reasonable cost within a
//             timely delivery period.
//           </p>

//           {/* Call-to-Action Button */}
//           <div className="mt-8">
//             <button className="px-6 py-3 bg-green-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105">
//               Contact Us
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Contact;


