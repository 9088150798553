import "./Product.css"
import React from 'react'
import 'animate.css';
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Products() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="sk">
      <h1 className="products">Products</h1>
      <div className="skill">
  
            <div className="skill-bx">
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                autoPlay={true} 
                autoPlaySpeed={2000}
                transitionDuration={100}
              >
{/* <div class="bg-cover bg-center ..." style="background-image: url(...)"></div> */}
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-3-UFsCEB2RzjSZM5_lJ26_0iW2eSBhcDoQ2bdKXLMg&s" alt="js" />
                 
                </div>

                <div className="item">
                  <img src="https://tiimg.tistatic.com/fp/1/006/995/finger-print-locker-for-office-employees-645.jpg" alt="html" />
                  
                </div>
                <div className="item">
                  <img src="https://5.imimg.com/data5/AP/HX/HB/SELLER-36481997/rfid-gym-lockers-250x250.jpg" alt="css" />
                 
                </div> 
                
                <div className="item">
                  <img src="https://images.jdmagicbox.com/comp/chennai/n4/044pxx44.xx44.180706131023.h6n4/catalogue/microleaf-software-technologies-pvt-ltd-vadapalani-chennai-industrial-locker-dealers-3v5g0x39ce-250.jpg" alt="react" />
                </div>

                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy040AXA2qLN-74Sq896HtzecDII-VOn4SjtN6aajAtA&s" alt="mongodb" />
                </div>

                <div className="shadow-md item">
                  <img src="https://tiimg.tistatic.com/fp/1/007/001/water-and-theme-park-key-less-electronic-lockers-971.jpg" alt="sql" />
                </div>

                <div className="shadow-md item">
                  <img src="https://2.wlimg.com/product_images/bc-small/2023/11/6207460/ms-locker-1677487717-6780291.jpg" alt="node" />
                </div>

                <div className="shadow-md item">
                  <img src="https://5.imimg.com/data5/SELLER/Default/2022/10/HO/TA/AR/36481997/7-3-250x250.jpg" alt="express" />
                </div>
               
                <div className="shadow-md item">
                  <img src="https://tiimg.tistatic.com/fp/1/005/491/reliable-biometric-safety-locker-207.jpg" alt="express" />
                </div>

                <div className="shadow-md item">
                  <img src="https://5.imimg.com/data5/SELLER/Default/2022/12/OL/LA/RY/36481997/microleaf-smart-mobile-phone-cabin-locker-edited-250x250.jpg" alt="express" />
                </div>
              
                <div className="item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6TZP-_PIeMunUxJGKVPLTtFw0TZgz2mJj6ltKBredZ4c4a5wzCkSRlT7MioQprnsHkFs&usqp=CAU" alt="express" />
                </div>
                
              </Carousel>
            </div>
        
    </div>
    </div>
  )
}

export default Products