import React from "react";
import { Widget, addResponseMessage, renderCustomComponent } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

function OptionsComponent({ onOptionSelect }) {
  return (
    <div>
      <p>Please choose an option:</p>
      <button className="hover:underline hover:bg-primary/80 duration-500 rounded-lg py-2 px-4" onClick={() => onOptionSelect("Locker enquiry")}>Locker enquiry</button>
      <br></br>
      <button className="hover:underline hover:bg-primary/80 duration-500 rounded-lg py-2 px-4" onClick={() => onOptionSelect("Service request")}>Service request</button>
      <br></br>
      <button className="hover:underline hover:bg-primary/80 duration-500 rounded-lg py-2 px-4" onClick={() => onOptionSelect("Job enquiry")}>Job enquiry</button>
      <br></br>
      <button className="hover:underline hover:bg-primary/80 duration-500 rounded-lg py-2 px-4"onClick={() => onOptionSelect("Customer Support")}>Customer Support</button>
      <br></br>
      <button className="hover:underline hover:bg-primary/80 duration-500 rounded-lg py-2 px-4"onClick={() => onOptionSelect("Other")}>Other</button>
      <br></br>

    </div>
  );
}

function Chatbot() {
  React.useEffect(() => {
    addResponseMessage("Welcome to our website! How can I help you?");
    renderCustomComponent(OptionsComponent, { onOptionSelect: handleOptionSelect });
  }, []);

  const handleOptionSelect = (option) => {
    addResponseMessage(`You selected: ${option}`);
  };

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message: ${newMessage}`);
    // Provide a response
    if (newMessage.toLowerCase().includes("hello")) {
      addResponseMessage("Hello! How can I assist you today?");
    } else {
      addResponseMessage("I'm here to help with any questions you have!");
    }
  };

  return (
    <Widget
      title="Chat with Us"
      subtitle="We are here to assist you"
      handleNewUserMessage={handleNewUserMessage}
    />
  );
}

export default Chatbot;
