import React from "react";
import "./Services.css";
import { FaCameraRetro } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { SlNote } from "react-icons/sl";



const skillsData = [
  {
    name: "Cost Effective",
    icon: 

  
    <GiNotebook size={40} style={{  background: 'linear-gradient(to right, #10b981, #06b6d4)', 
  
      display: 'inline-block' }}/>
    ,
    link: "#",
    description:
      " Minimize costs and maximize profits with Microleaf's automated package lockers, enabling secure pickup, drop-off, and storage at any location or office without any security vulnerabilities.  ",
    aosDelay: "100",
  },
  {
    name: "Modern Technology",
    icon: <SlNote  size={40} style={{  background: 'linear-gradient(to right, #10b981, #06b6d4)', 
    
      display: 'inline-block' }}/>
   ,
    
   
    link: "#",
    description:
      "An essential part of modern workplace management Microleaf Smart Locker Solutions provides you derivative over your assets, wherever they are.",
    aosDelay: "300",
  },
  {
    name: "Secure Storage",
    icon: <SlNote size={40} style={{ background: 'linear-gradient(to right, #10b981, #06b6d4)', 
      
      display: 'inline-block'}}/>,
    
    link: "#",
    description:
      " Opt for self-service to save IT resources.Secure storage ensures encrypted protection of sensitive data from unauthorized access or tampering. ",
    aosDelay: "500",
  },
  {
    name: "Extensibility",
    icon:
    <FaCameraRetro size={40} style={{  background: 'linear-gradient(to right, #22c55e, #06b6d4)', 
     
      display: 'inline-block' }} /> , 
  
    link: "#",
    description:
      "Extensions can be through the addition of new functionality or through modification of existing functionality. ",
    aosDelay: "700",
  },
];

const Services = () => {
  return (
    <>
      <span id="about"></span>
      <div className="bg-gray-100 dark:bg-black dark:text-white py-12 sm:grid sm:place-items-center">
        <div className="container">
          {/* Header */}
          <div className="pb-12 text-center space-y-3">
            {/* <h1
              data-aos="fade-up"
              className="text-3xl font-semibold sm:text-3xl text-primary bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text  flex flex-col items-center justify-center "
            >
              About Us
            </h1> */}
            {/* <p
              data-aos="fade-up"
              className="text-gray-600 dark:text-gray-350 text-sm"
            >
              We are self-service data analytics software that lets you create
              visually.
            </p> */}
          </div>

          {/* services cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 ">
            {skillsData.map((skill) => (
              <div
                key={skill.name}
                data-aos="fade-up"
                data-aos-delay={skill.aosDelay}
                className="card space-y-3 sm:space-y-4 p-4 "
              >
                <div className="vp  overflow-hidden text-gray-600  dark:text-gray-350   hover:bg-gradient-to-t  hover:from-green-500 hover:to-blue-500 hover:shadow-lg hover:scale-105 hover:text-white hover-icon:white ">
                  <div className="icon color-black ">{skill.icon}</div>
                  <h1 className="text-lg font-semibold text-center ">{skill.name}</h1>
                  <p className="text-justify">{skill.description}</p>
                  <br></br>
                </div>
              </div>
            ))}
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-offset="0"
            className="text-center mt-4 sm:mt-8"
          >
            <button className="text-primary bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text  flex flex-col items-center justify-center">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
